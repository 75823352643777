<template>
  <main>
    <trac-back-button class="pb-4">
      <span class=" text-gray-600">
        Back
      </span>
    </trac-back-button>
    <h1 class="text-gray-800 font-semibold capitalize text-sm">
      Fund Wallet Via {{ $route.params.type }} Transfer
    </h1>
    <div class="shadow pt-10 pb-40   px-96 border w-full rounded-lg mt-8">
      <div class="flex flex-col justify-center items-center  mx-auto">
        <img class="w-16 h-16 " src="@/assets/svg/houseactivetwo.svg" alt="" />
        <h2 class="  text-lg font-bold capitalize text-primaryBlue mt-5">
          Add Transaction Details
        </h2>
        <p class="text-xs text-gray-700 mt-2">
          Please fill the details below to complete this transaction
        </p>
      </div>
      <div class="mt-10">
        <trac-input type="number" placeholder="Amount"></trac-input>
        <trac-textarea class="mt-5" placeholder="Description"> </trac-textarea>
        <trac-button
          @click.native="sendToAccountModal = true"
          class="w-full mt-5"
        >
          <span>FUND WALLET</span>
        </trac-button>
      </div>
      <trac-modal
        v-if="sendToAccountModal"
        @close="sendToAccountModal = false"
        size="max-w-xl"
      >
        <div class="p-4">
          <div class=" font-semibold text-gray-800 text-sm">
            Send to Account
          </div>
          <div
            class=" flex justify-center items-center text-2xl font-bold mt-5"
          >
            {{ amount | formatPrice(false) }}
          </div>
          <div class="pb-8 pt-4 mx-24 text-xs text text-gray-700 ">
            <div
              class="flex flex-col gap-4 bg-gray-200 p-8  rounded-md"
              v-if="$route.params.type === 'bank'"
            >
              <p class="grid grid-cols-2 gap-3">
                <span class=" text-right">
                  Account Number
                </span>
                <span class="font-bold ml-3 flex items-center"
                  >1234567890
                  <div>
                    <svg
                      class="w-5 h-5 ml-3 cursor-pointer"
                      viewBox="0 0 45 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 8.16658C0.5 4.1165 3.78325 0.833252 7.83333 0.833252L37.1667 0.833252C41.2168 0.833252 44.5 4.1165 44.5 8.16659V37.4999C44.5 41.55 41.2168 44.8333 37.1667 44.8333H7.83333C3.78324 44.8333 0.5 41.55 0.5 37.4999V8.16658Z"
                        fill="#EFFAFF"
                      />
                      <rect
                        x="20.5276"
                        y="20.8607"
                        width="11.8345"
                        height="11.8345"
                        rx="2.2"
                        stroke="#003283"
                        stroke-width="2.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.8861 26.3229H15.9757C14.9702 26.3229 14.155 25.5077 14.155 24.5022V16.3091C14.155 15.3036 14.9702 14.4884 15.9757 14.4884H24.1688C25.1744 14.4884 25.9895 15.3036 25.9895 16.3091V17.2194"
                        stroke="#003283"
                        stroke-width="2.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </span>
              </p>
              <p class="grid grid-cols-2 gap-3">
                <span class=" text-right">
                  Bank
                </span>
                <span class="font-bold ml-3">Sterling Bank</span>
              </p>
              <p class="grid grid-cols-2 gap-3">
                <span class=" text-right">
                  Account Name
                </span>
                <span class="font-bold ml-3">John Doe</span>
              </p>
            </div>
            <div
              class=" text-xs bg-gray-200 flex items-center py-4 justify-center text-center gap-4 "
              v-else
            >
              <span class=" text-primaryBlue">
                Code
              </span>
              <div class=" font-bold">
                *990*220*009200920*25000#
              </div>
              <div>
                <svg
                  class="w-5 h-5 ml-3 cursor-pointer"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.5 8.16658C0.5 4.1165 3.78325 0.833252 7.83333 0.833252L37.1667 0.833252C41.2168 0.833252 44.5 4.1165 44.5 8.16659V37.4999C44.5 41.55 41.2168 44.8333 37.1667 44.8333H7.83333C3.78324 44.8333 0.5 41.55 0.5 37.4999V8.16658Z"
                    fill="#EFFAFF"
                  />
                  <rect
                    x="20.5276"
                    y="20.8607"
                    width="11.8345"
                    height="11.8345"
                    rx="2.2"
                    stroke="#003283"
                    stroke-width="2.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.8861 26.3229H15.9757C14.9702 26.3229 14.155 25.5077 14.155 24.5022V16.3091C14.155 15.3036 14.9702 14.4884 15.9757 14.4884H24.1688C25.1744 14.4884 25.9895 15.3036 25.9895 16.3091V17.2194"
                    stroke="#003283"
                    stroke-width="2.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <trac-button @button-clicked="sendToAccountModal = false" class=" w-full uppercase">
            <span>
              I have seen it
            </span>
          </trac-button>

          <div
            @click="sendToAccountModal = false"
            class=" text-secondaryRed text-center cursor-pointer mt-4 mb-5 text-xs uppercase"
          >
            Cancel
          </div>
        </div>
      </trac-modal>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      sendToAccountModal: false,
      amount: 35000,
    };
  },
};
</script>

<style></style>
